// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CallLogViewModel from '../../../../viewModel/resource/CallLogViewModel';
import CallLogDTO from '../../dto/resource/CallLogDTO';

export default abstract class CallLogModel extends BaseModel<CallLogDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get targetSystemName() { return this.dto.targetSystemName; }
  set targetSystemName(value) { this.dto.targetSystemName = value; }
  /**
  */
  get correlationId() { return this.dto.correlationId; }
  set correlationId(value) { this.dto.correlationId = value; }
  /**
  * @type {date-time}
  */
  get requestTime() { return this.dto.requestTime; }
  set requestTime(value) { this.dto.requestTime = value; }
  /**
  */
  get requestType() { return this.dto.requestType; }
  set requestType(value) { this.dto.requestType = value; }
  /**
  */
  get requestUrl() { return this.dto.requestUrl; }
  set requestUrl(value) { this.dto.requestUrl = value; }
  /**
  */
  get requestBody() { return this.dto.requestBody; }
  set requestBody(value) { this.dto.requestBody = value; }
  /**
  * @type {date-time}
  */
  get responseTime() { return this.dto.responseTime; }
  set responseTime(value) { this.dto.responseTime = value; }
  /**
  * @type {int32}
  */
  get responseStatusCode() { return this.dto.responseStatusCode; }
  set responseStatusCode(value) { this.dto.responseStatusCode = value; }
  /**
  */
  get responseBody() { return this.dto.responseBody; }
  set responseBody(value) { this.dto.responseBody = value; }
  /**
  */
  get responseInfo() { return this.dto.responseInfo; }
  set responseInfo(value) { this.dto.responseInfo = value; }
  /**
  */
  get exceptionMessage() { return this.dto.exceptionMessage; }
  set exceptionMessage(value) { this.dto.exceptionMessage = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modified = qs.modified;
    this.dto.targetSystemName = qs.targetsystemname;
    this.dto.correlationId = qs.correlationid;
    this.dto.requestTime = qs.requesttime;
    this.dto.requestType = qs.requesttype;
    this.dto.requestUrl = qs.requesturl;
    this.dto.requestBody = qs.requestbody;
    this.dto.responseTime = qs.responsetime;
    value = Number.parseFloat(qs.responsestatuscode);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.responseStatusCode = value;
    this.dto.responseBody = qs.responsebody;
    this.dto.responseInfo = qs.responseinfo;
    this.dto.exceptionMessage = qs.exceptionmessage;
  }
}
